import { Reducer } from "react";
import { castDraft, produce } from "immer";
import { ZodError, ZodType } from "zod";

// export const emptyEditorState: EditorState<number> = {
//   // menu: {
//   //   categories: [],
//   //   options: [],
//   //   products: [],
//   //   steps: [],
//   // },
//   document: 2,
//   validationError: null,
// };

export type PathSelector = (value: unknown) => boolean;
export type PathElement = string | number;
export type Path = (PathElement | PathSelector)[];

export type EditorState<T> = {
  document: T;
  taxRates: { [key: string]: number };
  validationError: ZodError<T> | null;
};

export type EditorAction =
  | { type: "replace"; document: unknown }
  | { type: "replaceTax"; taxRates: unknown }
  | { type: "validate" }
  | { type: "setValue"; path: Path; value: unknown }
  | { type: "addItem"; path: Path; item: unknown }
  | { type: "replaceItem"; path: Path; item: unknown; index: number }
  | { type: "removeItem"; path: Path; item: unknown };

export type EditorReducer<T> = Reducer<EditorState<T>, EditorAction>;

type ObjectElement = {
  [key: PathElement]: ObjectElement;
};

export const editorReducerFactory =
  <T>(schema: ZodType<T>) =>
  (state: EditorState<T>, action: EditorAction): EditorState<T> => {
    return produce(state, (draft) => {
      if (
        action.type === "setValue" ||
        action.type === "addItem" ||
        action.type === "removeItem" ||
        action.type === "replaceItem"
      ) {
        // clone path list
        const stack = action.path.map((el) => el);

        let target: ObjectElement = draft.document as unknown as ObjectElement;

        if (stack.length === 0) throw new Error(`path list is empty`);

        let key = stack[0];

        while (stack.length > 0) {
          const next = stack.shift();

          // impossible because of length check in while, but required for TS
          if (next === undefined) break;

          key = next;

          // if path element is a function, the use it to find the index
          if (typeof key === "function") {
            if (Array.isArray(target)) {
              key = target.findIndex(key);
            } else {
              throw new Error(
                `function PathElement requires array for selection`
              );
            }
          }

          // if selector key is invalide
          if (target[key] === undefined)
            throw new Error(
              `key '${key}' doesn't exist in key chain: ${action.path.toString()}`
            );

          // if not reached the and of path list, let's interate
          if (stack.length > 0) {
            target = target[key];
          }
        } // while

        // it is unnecessary because while loop above resolves function, so this just makes TS happy
        if (typeof key === "function") return;

        if (action.type === "setValue") {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          target[key] = action.value;

          console.log(
            `setValue: (${key}) (${typeof key}) key '${key}' set to (${typeof action.value}) '${
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              action.value
            }'`
          );
        } else if (
          action.type === "addItem" ||
          action.type === "removeItem" ||
          action.type === "replaceItem"
        ) {
          if (action.type === "addItem") {
            if (!Array.isArray(target[key]))
              throw new Error(
                `target[key:${key}] is not an array, can't add new item`
              );

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target[key].push(action.item);

            console.log(
              `addItem: to key (${typeof key}) '${key}', item ${
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                action.item
              }`
            );
          } else if (action.type === "removeItem") {
            if (!Array.isArray(target[key]))
              throw new Error(
                `target[key:${key}] is not an array, can't add new item`
              );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target[key].splice(action.item, 1);

            console.log(
              `removeItem: key (${typeof key}) '${key}' removed from array (${
                action.item as number
              })`
            );
          } else if (action.type === "replaceItem") {
            console.log("REPLACE_ITEM");
            if (!Array.isArray(target[key]))
              throw new Error(
                `target[key:${key}] is not an array, can't add new item`
              );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target[key][action.index] = action.item;

            console.log(
              `replaceItem: key (${typeof key}) '${key}' replaced in array`
            );
          }
        } // addItem, removeItem
      } // setValue, addItem, removeItem
      else if (action.type === "validate") {
        const result = schema.safeParse(draft.document);
        if (!result.success) {
          // TS error solution: https://immerjs.github.io/immer/typescript/#cast-utilities
          draft.validationError = castDraft(result.error);
        } else {
          draft.validationError = null;
        }
      } // validate
      else if (action.type === "replace") {
        draft.document = castDraft<T>(action.document as T);
      } // replace
      else if (action.type === "replaceTax") {
        draft.taxRates = castDraft<{ [key: string]: number }>(
          action.taxRates as { [key: string]: number }
        );
      } // replace
    });
  };

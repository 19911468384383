import { z } from "zod";
import {
  FulfillmentType,
  OptionPresentation,
  OptionProperty,
  ProductLabels,
  ProductPresentation,
  StepPresentation,
  TaxRate,
} from "./schema.generated";
import { expectedLanguages, expectedTaxRates } from "./settings";
import {
  refinementProductIdExistsInCategoryKeyFactory as refinementProductIdExistsInCategoryFactory,
  refinementStepIdExistsInProductStep,
  refinementOptionIdExistsInStepOption,
  refinementUniqueId,
  refinementProductStepLimits,
  refinementStepOptionLimits,
  refinementProductStepAndStepOptionLimits,
  //refinementFulfillmentTypeMatchesProductStep,
  refinementUniqueStepId,
  refinementUniqueOptionId,
  //refinementUniquePosition,
  refinementSingleSelectStepValid,
  refinementOnlyConfigurableProductHasProductSteps,
  refinementKeysMustExistFactory,
  refinementHungarianTaxRates,
  refinementProductIdExistsInCategory,
  refinementStepIdExistsInProduct,
  refinementOptionIdExistsInAnyStepOption,
  refinementProductLabel,
  // refinementKeysMustExistFactory,
} from "./menu.refinements";

// DONE:

// check if products exist in category.productIds
// check if products exist in category.upsellProductIds
// check if steps exist for product.productSteps[].stepId
// check total, distinct amounts in a step
// check amounts in an option
// check if options exist for each product.productSteps[].stepOptions[].optionId
// check if category is not empty, it must contain product
// check duplicate ids in products, steps, options
// availableForFulfillment must match fulfillmentType of productSteps
// max amounts must be a positive number
// step ids in a product must be unique
// option ids in a step must be unique
// move single select to productStep
// "order" position parameters should be orderable, and thus unique
// a product és az option taxRates mezője meg kell hogy egyezzen?
// singleSelect step should have only one option (if any) with defaulAmount = 1
// if product is configurable, it should have productSteps and stepOptions

// TODO:

// malomban menü-ben lehet olyan hogy az opcio áfa kulcsa eltér a product áfa kulcsától
// előfordulhat hogy a menüben nincs egy step és egy option sem,
// OptionPresentation -> when image, check for image
// validate urls

// TODO LOW:

// check if all products belong to at least one category
// check if all steps are in products. do we need step entity at all?
// check if all options are in steps

export const idSchema = z.number().int().positive();

export const priceSchema = z.number().nonnegative();

// export const translatedStringSchema = z
//   .record(z.string())
//   .superRefine(refinementKeysMustExistFactory(expectedLanguages));

/*export const translatedStringSchema = z.object({
  en: z.string(),
  hu: z.string().nullable(),
});*/

export const translatedStringSchema = z
  .record(z.string())
  .superRefine(refinementKeysMustExistFactory(expectedLanguages));

export const taxRateObjectSchema = z.record(z.number());

export const imageSourceSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const imageBlurhashSchema = z.object({
  blurhash: z.string(),
  widthSegments: z.number().int().positive(),
  heightSegments: z.number().int().positive(),
});

export const imageGeneratedSchema = z.object({
  blurhash: imageBlurhashSchema.nullable(),
  sources: z.array(imageSourceSchema),
});

export const imageSchema = z.object({
  original: imageSourceSchema,
  generated: imageGeneratedSchema.nullable(),
});

export const fulfillmentTypeSchema = z.nativeEnum(FulfillmentType);

export const fulfillmentTypeSchemaForProductStep = z.array(
  z.nativeEnum(FulfillmentType)
);

export const taxRateSchema = z.nativeEnum(TaxRate);

export const taxRateByFulfillmentSchema = z.object({
  takeAway: taxRateSchema,
  dineIn: taxRateSchema,
});

export const optionPresentationSchema = z.nativeEnum(OptionPresentation);

export const vendelExternalIdScheme = z.string().nullable();

export const optionVendel = z.object({
  externalId: vendelExternalIdScheme,
});

export const optionMobileSchema = z.object({
  productStepOptionImage: imageSchema.nullable(),
});

export const optionPropertySchema = z.nativeEnum(OptionProperty);

export const optionSchema = z
  .object({
    id: idSchema,
    taxRates: taxRateByFulfillmentSchema,
    taxModifier: taxRateSchema.nullable(),
    name: translatedStringSchema,
    editorName: z.string(),
    description: translatedStringSchema.nullable(),
    presentation: optionPresentationSchema,
    properties: z.array(optionPropertySchema),
    mobile: optionMobileSchema.nullable(),
    vendel: optionVendel,
  })
  .superRefine(refinementHungarianTaxRates);

export const stepPresentationSchema = z.nativeEnum(StepPresentation);

export const stepOptionSchema = z
  .object({
    optionId: idSchema,
    price: priceSchema.nullable(),
    min: z.number().int().nonnegative().nullable(),
    max: z.number().int().positive().nullable(),
    position: z.number().int().nonnegative(),
    defaultAmount: z.number().int().nonnegative(),
    statPrice: priceSchema.nullable(),
    containerPrice: priceSchema.nullable(),
  })
  .superRefine(refinementStepOptionLimits);

export const stepSchema = z
  .object({
    id: idSchema,
    name: translatedStringSchema,
    editorName: z.string(),
    //isExtra: z.boolean(),
    presentation: stepPresentationSchema,
    description: translatedStringSchema.nullable(),
    selectionText: translatedStringSchema.nullable(),
    stepOptions: z.array(stepOptionSchema).min(1),
    minDistinct: z.number().int().positive().nullable(),
    maxDistinct: z.number().int().positive().nullable(),
    minTotal: z.number().int().positive().nullable(),
    maxTotal: z.number().int().positive().nullable(),
    singleSelect: z.boolean().nullable(),
    columnCount: z.number().int().nonnegative(),
  })
  .superRefine(refinementUniqueOptionId)
  .superRefine(refinementProductStepAndStepOptionLimits)
  .superRefine(refinementSingleSelectStepValid)
  .superRefine(refinementProductStepLimits);

export const productStepSchema = z.object({
  stepId: idSchema,
  //.superRefine(refinementUniquePosition),
  visible: z.boolean(),
  availableForFulfillment: fulfillmentTypeSchemaForProductStep,
  position: z.number().int().nonnegative(),
  isExtra: z.boolean(),
});

export const productPresentationSchema = z.nativeEnum(ProductPresentation);

export const productLabelSchema = z.nativeEnum(ProductLabels);

export const productVendel = z.object({
  externalId: vendelExternalIdScheme,
});

export const productMobileSchema = z.object({
  menuItemImage: imageSchema.nullable(),
  productCoverImage: imageSchema.nullable(),
});

export const productSchema = z
  .object({
    id: idSchema,
    price: priceSchema,
    taxRates: taxRateByFulfillmentSchema,
    productSteps: z.array(productStepSchema),
    //.superRefine(refinementUniquePosition),
    position: z.number().int().nonnegative(),
    availableForFulfillment: fulfillmentTypeSchema.nullable(),
    availableDates: z.array(z.string().regex(/^\d{4}-\d{2}-\d{2}$/)).nullable(),
    name: translatedStringSchema,
    editorName: z.string(),
    labels: z.array(productLabelSchema),
    pager: z.boolean(),
    description: translatedStringSchema.nullable(),
    presentation: productPresentationSchema,
    mobile: productMobileSchema.nullable(),
    vendel: productVendel,
    statPrice: priceSchema.nullable(),
    receiptMark: z.boolean(),
    containerPrice: priceSchema.nullable(),
  })
  //.superRefine(refinementFulfillmentTypeMatchesProductStep)
  .superRefine(refinementUniqueStepId)
  .superRefine(refinementOnlyConfigurableProductHasProductSteps)
  .superRefine(refinementHungarianTaxRates);

export const categoryMobileSchema = z.object({
  menuCoverImage: imageSchema,
  icon: z.string(),
});

export const categorySchema = z.object({
  id: idSchema,
  position: z.number().int().nonnegative(),
  productIds: z.array(idSchema).min(1),
  name: translatedStringSchema,
  editorName: z.string(),
  mobile: categoryMobileSchema.nullable(),
  upsellProductIds: z.array(idSchema).nullable(),
  upsellName: translatedStringSchema,
  upsellButton: translatedStringSchema,
});

export const menuSchema = z
  .object({
    options: z.array(optionSchema).superRefine(refinementUniqueId),
    steps: z.array(stepSchema).superRefine(refinementUniqueId),
    products: z.array(productSchema).min(1).superRefine(refinementUniqueId),
    categories: z.array(categorySchema).min(1).superRefine(refinementUniqueId),
    taxRates: taxRateObjectSchema,
  })
  .superRefine(refinementStepIdExistsInProductStep)
  .superRefine(refinementOptionIdExistsInStepOption)
  .superRefine(refinementProductIdExistsInCategory)
  .superRefine(refinementStepIdExistsInProduct)
  .superRefine(refinementOptionIdExistsInAnyStepOption)
  .superRefine(refinementProductIdExistsInCategoryFactory("productIds"))
  .superRefine(refinementProductLabel)
  .superRefine(refinementProductIdExistsInCategoryFactory("upsellProductIds"));

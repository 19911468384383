export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Table: string;
  Time: Date;
  TranslatedString: Record<string, string>;
  UUID: string;
};

export type AdminLocation = {
  description: Scalars["TranslatedString"];
  disabledOnlineOrdering: Scalars["Boolean"];
  disabledOptions: Array<Scalars["Int"]>;
  disabledProducts: Array<Scalars["Int"]>;
  id: Scalars["ID"];
  name: Scalars["TranslatedString"];
  waitTime: Maybe<Scalars["Int"]>;
};

export type AdminUser = {
  displayName: Scalars["String"];
  email: Scalars["String"];
  id: Scalars["UUID"];
};

export type CardTokenInput = {
  CVC: Scalars["String"];
  ExpMonth: Scalars["String"];
  ExpYear: Scalars["String"];
  Number: Scalars["String"];
};

export type Cart = {
  fulfillmentType: FulfillmentType;
  locationId: Scalars["ID"];
  products: Array<CartProduct>;
  table: Maybe<Scalars["Table"]>;
};

export type CartHistory = {
  items: Array<CartInHistory>;
};

export type CartInHistory = {
  cart: Cart;
  createdAt: Scalars["Time"];
  hydratedCart: HydratedCart;
  orderId: Scalars["ID"];
};

export type CartInput = {
  fulfillmentType: FulfillmentType;
  locationId: Scalars["ID"];
  products: Array<CartProductInput>;
  table: InputMaybe<Scalars["Table"]>;
};

export type CartIssue = {
  code: Scalars["String"];
  message: Scalars["String"];
  params: Maybe<CartIssueParam>;
  path: Array<Scalars["String"]>;
};

export type CartIssueParam = {
  cartProductIndex: Maybe<Scalars["Int"]>;
  optionId: Maybe<Scalars["Int"]>;
  stepId: Maybe<Scalars["Int"]>;
  type: Scalars["String"];
};

export type CartOption = {
  count: Scalars["Int"];
  optionId: Scalars["ID"];
  stepId: Scalars["ID"];
};

export type CartOptionInput = {
  count: Scalars["Int"];
  optionId: Scalars["ID"];
  stepId: Scalars["ID"];
};

export type CartProduct = {
  count: Scalars["Int"];
  options: Array<CartOption>;
  productId: Scalars["ID"];
  state: CartProductState;
};

export type CartProductInput = {
  count: Scalars["Int"];
  options: Array<CartOptionInput>;
  productId: Scalars["ID"];
  state: CartProductState;
};

export enum CartProductState {
  Deleted = "Deleted",
  Draft = "Draft",
  Final = "Final",
}

export type Category = {
  id: Scalars["ID"];
  mobile: Maybe<CategoryMobile>;
  name: Scalars["TranslatedString"];
  editorName: Scalars["String"];
  position: Scalars["Int"];
  productIds: Array<Scalars["ID"]>;
  upsellProductIds: Maybe<Array<Scalars["ID"]>>;
  upsellName: Scalars["TranslatedString"];
  upsellButton: Scalars["TranslatedString"];
};

export type CategoryMobile = {
  icon: Scalars["String"];
  menuCoverImage: Image;
};

export type Config = {
  menu: Menu;
};

export type ConfigVendel = {
  ectId: Scalars["String"];
  id: Scalars["Int"];
  navId: Scalars["String"];
  translatedStringLocale: Scalars["String"];
};

export type ConfigVendelTaxRateExternalId = {
  receiptItemId: Scalars["String"];
  taxRate: TaxRate;
};

export enum FulfillmentType {
  DineIn = "DineIn",
  TakeAway = "TakeAway",
}

export type HydratedCart = {
  location: LocationHydrated;
  products: Array<HydratedCartProduct>;
  total: Array<TaxRatePrice>;
};

export type HydratedCartProduct = {
  name: Scalars["TranslatedString"];
  options: Maybe<Scalars["TranslatedString"]>;
  price: Array<TaxRatePrice>;
};

export type Image = {
  generated: Maybe<ImageGenerated>;
  original: ImageSource;
};

export type ImageBlurhash = {
  blurhash: Scalars["String"];
  heightSegments: Scalars["Int"];
  widthSegments: Scalars["Int"];
};

export type ImageGenerated = {
  blurhash: Maybe<ImageBlurhash>;
  sources: Array<ImageSource>;
};

export type ImageSource = {
  value: Scalars["String"];
  label: Scalars["String"];
};

export enum ItemType {
  Option = "Option",
  Product = "Product",
}

export type Location = {
  appleMerchantId: Scalars["String"];
  externalId: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["TranslatedString"];
  position: Position;
  restaurantCloseTime: Scalars["String"];
  restaurantOpenTime: Scalars["String"];
  stripeAccountId: Scalars["String"];
};

export type LocationHydrated = {
  name: Scalars["TranslatedString"];
};

export type Menu = {
  categories: Array<Category>;
  options: Array<Option>;
  products: Array<Product>;
  steps: Array<Step>;
  taxRates: { [key: string]: number };
};

export type MenuDeployInput = {
  comment: Scalars["String"];
  locationId: Scalars["Int"];
};

export type MenuIssue = {
  code: Scalars["String"];
  message: Scalars["String"];
  path: Array<Scalars["String"]>;
};

export type Mutation = {
  addBirthdayToProfile: Scalars["Boolean"];
  addEmailToProfile: Scalars["Boolean"];
  addNameToProfile: Scalars["Boolean"];
  adminCopyMenu: Scalars["Boolean"];
  adminDeployMenu: Array<MenuIssue>;
  adminLogin: Scalars["String"];
  adminLogout: Scalars["Boolean"];
  adminSetDisableOptionOrProduct: Scalars["Boolean"];
  adminSetLocationDisabledOnlineOrdering: Scalars["Boolean"];
  deleteUser: Scalars["Boolean"];
  deleteUserSavedCard: Scalars["Boolean"];
  editUserProfile: Scalars["Boolean"];
  login: Scalars["Boolean"];
  logout: Scalars["Boolean"];
  register: Scalars["Boolean"];
  resendCode: Scalars["Boolean"];
  verifyCode: Scalars["String"];
};

export type MutationAddBirthdayToProfileArgs = {
  Birthday: Scalars["String"];
};

export type MutationAddEmailToProfileArgs = {
  Email: Scalars["String"];
};

export type MutationAddNameToProfileArgs = {
  Name: Scalars["String"];
};

export type MutationAdminCopyMenuArgs = {
  sourceMenuId: Scalars["Int"];
  targetMenuId: Scalars["Int"];
};

export type MutationAdminDeployMenuArgs = {
  input: MenuDeployInput;
};

export type MutationAdminLoginArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationAdminSetDisableOptionOrProductArgs = {
  disableType: SetDisableType;
  itemId: Scalars["Int"];
  itemType: ItemType;
  locationId: Scalars["Int"];
};

export type MutationAdminSetLocationDisabledOnlineOrderingArgs = {
  locationId: Scalars["Int"];
  value: Scalars["Boolean"];
};

export type MutationDeleteUserSavedCardArgs = {
  CardToken: Scalars["String"];
};

export type MutationEditUserProfileArgs = {
  UserData: UserDataInput;
};

export type MutationLoginArgs = {
  PhoneNumber: Scalars["String"];
};

export type MutationRegisterArgs = {
  PhoneNumber: Scalars["String"];
  TAndCAccepted: Scalars["Boolean"];
};

export type MutationResendCodeArgs = {
  PhoneNumber: Scalars["String"];
};

export type MutationVerifyCodeArgs = {
  Code: Scalars["String"];
  PhoneNumber: Scalars["String"];
};

export type Option = {
  description: Maybe<Scalars["TranslatedString"]>;
  id: Scalars["ID"];
  mobile: Maybe<OptionMobile>;
  name: Scalars["TranslatedString"];
  editorName: Scalars["String"];
  presentation: OptionPresentation;
  properties: Array<OptionProperty>;
  taxRates: TaxRateByFulfillment;
  taxModifier: Maybe<TaxRate>;
  vendel: OptionVendel;
};

export type OptionMobile = {
  productStepOptionImage: Maybe<Image>;
};

export enum OptionPresentation {
  Image = "Image",
  Text = "Text",
  Nothing = "Nothing",
}

export enum OptionProperty {
  Packaging = "Packaging",
}

export type OptionVendel = {
  externalId: Maybe<Scalars["String"]>;
};

export type Order = {
  cart: OrderCart;
  hydratedCart: HydratedCart;
  orderNumber: Scalars["String"];
  orderTime: Scalars["Time"];
};

export type OrderCart = {
  fulfillmentType: FulfillmentType;
  locationId: Scalars["ID"];
  products: Array<OrderCartProduct>;
  table: Maybe<Scalars["Table"]>;
};

export type OrderCartProduct = {
  canReOrder: Scalars["Boolean"];
  count: Scalars["Int"];
  options: Array<CartOption>;
  productId: Scalars["ID"];
  state: CartProductState;
};

export type OrderResponse = {
  orderNumber: Scalars["String"];
  stripeClientSecret: Scalars["String"];
};

export type Position = {
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type Product = {
  availableDates: Maybe<Array<Scalars["String"]>>;
  availableForFulfillment: Maybe<FulfillmentType>;
  description: Maybe<Scalars["TranslatedString"]>;
  id: Scalars["ID"];
  mobile: Maybe<ProductMobile>;
  name: Scalars["TranslatedString"];
  editorName: Scalars["String"];
  position: Scalars["Int"];
  labels: Array<ProductLabels>;
  presentation: ProductPresentation;
  price: Scalars["Float"];
  pager: Scalars["Boolean"];
  productSteps: Array<ProductStep>;
  taxRates: TaxRateByFulfillment;
  vendel: ProductVendel;
  statPrice: Maybe<Scalars["Float"]>;
  receiptMark: Scalars["Boolean"];
  containerPrice: Maybe<Scalars["Float"]>;
};

export type ProductMobile = {
  menuItemImage: Maybe<Image>;
  productCoverImage: Maybe<Image>;
};

export enum ProductPresentation {
  Configurable = "Configurable",
  Simple = "Simple",
}

export enum ProductLabels {
  CustomConfig = "CustomConfig",
}

export type ProductStep = {
  availableForFulfillment: Array<FulfillmentType>;
  position: Scalars["Int"];
  stepId: Scalars["ID"];
  visible: Scalars["Boolean"];
  isExtra: Scalars["Boolean"];
};

export type ProductVendel = {
  externalId: Maybe<Scalars["String"]>;
};

export type Query = {
  adminGetMenu: Menu;
  adminLocations: Array<AdminLocation>;
  adminUser: AdminUser;
  config: Config;
  getOrders: Array<Order>;
  getProfileData: UserData;
  locations: Array<Location>;
  order: Maybe<OrderResponse>;
  refreshToken: Scalars["String"];
  test: Step;
};

export type QueryAdminGetMenuArgs = {
  locationId: Scalars["ID"];
};

export type QueryConfigArgs = {
  locationId: Scalars["ID"];
};

export type QueryGetOrdersArgs = {
  UserId: Scalars["String"];
  locationId: Scalars["Int"];
};

export type QueryOrderArgs = {
  CardToken: InputMaybe<Scalars["String"]>;
  Cart: CartInput;
  SaveCard: Scalars["Boolean"];
  SelectedCardToken: InputMaybe<Scalars["String"]>;
  Total: Scalars["Int"];
  UserId: Scalars["String"];
};

export enum SetDisableType {
  Disable = "Disable",
  Enable = "Enable",
}

export type Step = {
  description: Maybe<Scalars["TranslatedString"]>;
  id: Scalars["ID"];
  name: Scalars["TranslatedString"];
  editorName: Scalars["String"];
  selectionText: Maybe<Scalars["TranslatedString"]>;
  presentation: StepPresentation;
  stepOptions: Array<StepOption>;
  maxDistinct: Maybe<Scalars["Int"]>;
  maxTotal: Maybe<Scalars["Int"]>;
  minDistinct: Maybe<Scalars["Int"]>;
  minTotal: Maybe<Scalars["Int"]>;
  singleSelect: Maybe<Scalars["Boolean"]>;
  columnCount: Scalars["Int"];
  //isExtra: Scalars["Boolean"];
};

export type StepOption = {
  defaultAmount: Scalars["Int"];
  max: Maybe<Scalars["Int"]>;
  min: Maybe<Scalars["Int"]>;
  optionId: Scalars["ID"];
  position: Scalars["Int"];
  price: Maybe<Scalars["Float"]>;
  statPrice: Maybe<Scalars["Float"]>;
  containerPrice: Maybe<Scalars["Float"]>;
};

export enum StepPresentation {
  Cards = "Cards",
  List = "List",
}

export enum TaxRate {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
}

export type TaxRateByFulfillment = {
  dineIn: TaxRate;
  takeAway: TaxRate;
};

export type TaxRatePrice = {
  price: Scalars["Float"];
  taxRate: TaxRate;
};

export type UserData = {
  birthday: Maybe<Scalars["String"]>;
  card: Array<UserDataCard>;
  email: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
};

export type UserDataCard = {
  brand: Scalars["String"];
  last4: Scalars["String"];
  token: Scalars["String"];
};

export type UserDataInput = {
  birthday: InputMaybe<Scalars["String"]>;
  card: InputMaybe<Scalars["String"]>;
  email: InputMaybe<Scalars["String"]>;
  name: InputMaybe<Scalars["String"]>;
};

export type VendelCall = {
  caller: VendelCaller;
  created_at: Scalars["String"];
  id: Scalars["String"];
  items: Array<VendelItem>;
  price: VendelPrice;
  receipt: Maybe<VendelReceipt>;
  restaurant: VendelRestaurant;
  terminal: VendelTerminal;
  type: VendelCallType;
};

export enum VendelCallType {
  Kiosk = "kiosk",
  Mobile = "mobile",
}

export type VendelCaller = {
  available: Scalars["Boolean"];
  value: Scalars["String"];
};

export type VendelItem = {
  base_price: VendelPrice;
  category: VendelItemCategory;
  count: Scalars["Int"];
  external_id: Scalars["String"];
  name: Scalars["String"];
  options: Array<VendelItemOption>;
  total_price: VendelPrice;
  unit_price: VendelPrice;
  value_vat: Scalars["String"];
};

export type VendelItemCategory = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type VendelItemOption = {
  count: Scalars["Int"];
  id: Scalars["String"];
  price: VendelPrice;
  value: Scalars["String"];
  value_external_id: Scalars["String"];
  value_vat: Scalars["String"];
};

export type VendelPrice = {
  amount: Scalars["Float"];
  currency: Scalars["String"];
};

export type VendelReceipt = {
  documents: VendelReceiptDocument;
  extlines: Array<VendelReceiptExtLine>;
  itemlist: Array<VendelReceiptItem>;
  payments: Array<VendelReceiptPayment>;
};

export type VendelReceiptDocument = {
  document_number: Scalars["String"];
  submit_date: Scalars["String"];
  zcount_number: Scalars["String"];
};

export type VendelReceiptExtLine = {
  style: Scalars["Int"];
  value: Scalars["String"];
};

export type VendelReceiptItem = {
  comment: Scalars["String"];
  count: Scalars["Int"];
  id: Scalars["String"];
  name: Scalars["String"];
  price: Scalars["Float"];
  unit_name: Scalars["String"];
  unit_price: Scalars["Float"];
  vat_collector: Scalars["String"];
  vat_letter: Scalars["String"];
  xdsc_price: Scalars["Float"];
  xsvc_price: Scalars["Float"];
};

export type VendelReceiptPayment = {
  amount: Scalars["Float"];
  currency: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type VendelRestaurant = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type VendelTerminal = {
  ect_id: Scalars["String"];
  id: Scalars["Int"];
  nav_id: Scalars["String"];
  status: VendelTerminalStatus;
  type: VendelTerminalType;
};

export enum VendelTerminalStatus {
  Abort = "abort",
  BeforePayment = "before_payment",
  BeforeReceipt = "before_receipt",
  Process = "process",
  Success = "success",
}

export enum VendelTerminalType {
  Eatin = "eatin",
  Takeaway = "takeaway",
}

import { Badge, Menu, message } from "antd";
import { FC, Fragment, useEffect, useMemo, useReducer, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import {
  EditorContextProvider,
  EditorContextType,
  useClientContext,
} from "../editor/context";
import { editorReducerFactory, EditorState } from "../editor/logic";
import { Config } from "../lib/schema.generated";
import { expectedLanguages } from "../lib/settings";
import {
  PieChartOutlined,
  ScheduleOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { configSchema } from "../lib/config";
import { useSelectedMenuItemKey } from "../utils";
import { Breadcrumb } from "./Breadcrumb";
import {
  VapianoConfigContext,
  VapianoConfigContextProvider,
} from "./vapianoConfigContext";
import { getImages, getMenu, saveMenu } from "../api/client";
import { Image, SaveMenuRequest } from "../generated";
import { useMutation, useQuery } from "@tanstack/react-query";

const editorReducer = editorReducerFactory(configSchema);

export const MenuEditor: FC = () => {
  const clientContext = useClientContext();
  const [images, setImages] = useState<
    Array<{ label: string; value: string; previewUrl: string }>
  >([]);
  const params = useParams<"menuId">();
  const { data: imageData, isLoading: isImageLoading } = useQuery({
    queryKey: ["images", clientContext.selectedClient?.Id],
    queryFn: () =>
      getImages(
        clientContext.selectedClient !== null
          ? clientContext.selectedClient.Id
          : 0
      ),
  });
  const { data: saveMenuData, mutate: doSaveMenu } = useMutation({
    mutationKey: ["saveMenu"],
    mutationFn: saveMenu,
  });
  const [extraMenuFields, setExtraMenuFields] =
    useState<SaveMenuRequest | null>(null);

  useEffect(() => {
    if (saveMenuData !== undefined) void message.success("Menu saved");
  }, [saveMenuData]);

  useEffect(() => {
    if (imageData !== undefined) {
      const formattedImages: Array<{
        label: string;
        value: string;
        previewUrl: string;
      }> = imageData.data.map((image: Image) => {
        return {
          label: image.Name,
          value: image.Uuid,
          previewUrl: image.PreviewUrl,
        };
      });
      setImages(formattedImages);
    }
  }, [imageData]);

  if (params.menuId === undefined) throw new Error("menuId undefined");

  const menuId = Number.parseInt(params.menuId);

  const { data: menuData, isLoading: isMenuLoading } = useQuery({
    queryKey: ["menu", menuId],
    queryFn: () => getMenu(menuId),
  });

  useEffect(() => {
    if (menuData !== undefined) {
      dispatch({ type: "replace", document: menuData.data.Data });
      dispatch({ type: "replaceTax", taxRates: menuData.data.TaxRates });
      setExtraMenuFields({
        Id: menuData.data.Id,
        Name: menuData.data.Name,
        Description: menuData.data.Description,
        ClientId: menuData.data.ClientId,
        Data: menuData.data.Data,
      });
    }
  }, [menuData]);

  const emptyEditorState: EditorState<Config> = {
    document: {
      menu: {
        categories: [],
        options: [],
        products: [],
        steps: [],
        taxRates: {},
      },
    },
    taxRates: {},

    validationError: null,
  };

  const [state, dispatch] = useReducer(editorReducer, emptyEditorState);

  const editorContextValue: EditorContextType = useMemo(() => {
    return {
      dispatch,
    } as EditorContextType;
  }, [dispatch]);

  const vapianoEditorContextValue: VapianoConfigContext = useMemo(() => {
    return {
      expectedLanguages,
      useLanguageKey: "en",
      mediaFiles: images,
      document: state.document,
      validationError: state.validationError,
      taxRates: state.taxRates,
    };
  }, [images, state.document, state.taxRates, state.validationError]);

  const saveMenuToBackend = (document: unknown) => {
    if (extraMenuFields !== null && clientContext.selectedClient !== null) {
      const menuToSave: SaveMenuRequest = {
        Id: menuId,
        Description: extraMenuFields.Description,
        ClientId: clientContext.selectedClient.Id,
        Name: extraMenuFields.Name,
        Data: document,
      };
      void doSaveMenu(menuToSave);
    }
  };

  useEffect(() => {
    dispatch({ type: "validate" });
    if (state.document !== emptyEditorState.document) {
      void saveMenuToBackend(state.document);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.document]);

  const items: ItemType[] = useMemo(() => {
    return [
      {
        label: <Link to={`categories`}>Categories</Link>,
        key: "categories",
        icon: <SnippetsOutlined rev={undefined} />,
      },
      {
        label: <Link to={`products`}>Products</Link>,
        key: "products",
        icon: <PieChartOutlined rev={undefined} />,
      },
      {
        label: <Link to={`steps`}>Steps</Link>,
        key: "steps",
        icon: <SnippetsOutlined rev={undefined} />,
      },
      {
        label: <Link to={`options`}>Options</Link>,
        key: "options",
        icon: <PieChartOutlined rev={undefined} />,
      },
      {
        label: (
          <Fragment>
            <Link to={`validation`}>Validation</Link>
            <Badge
              style={{ marginLeft: 4 }}
              count={
                state.validationError !== null
                  ? state.validationError.issues.length
                  : 0
              }
            />
          </Fragment>
        ),
        key: "validation",
        icon: <ScheduleOutlined rev={undefined} />,
      },
    ];
  }, [state.validationError]);

  const selectedKeys = useSelectedMenuItemKey(items);

  if (isImageLoading || isMenuLoading) return null;

  return (
    <Fragment>
      <Menu selectedKeys={selectedKeys} mode="horizontal" items={items} />
      <Breadcrumb />
      <EditorContextProvider value={editorContextValue}>
        <VapianoConfigContextProvider value={vapianoEditorContextValue}>
          <Outlet />
        </VapianoConfigContextProvider>
      </EditorContextProvider>
    </Fragment>
  );
};

import React, { useCallback, useEffect } from "react";
import { Button, Col, Row, Table, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import {
  Checkbox,
  CheckboxNull,
  Image,
  InputNumber,
  InputString,
  InputTranslated,
  Select,
  Tag,
  ActionButton,
  SelectOptionType,
} from "../editor/components";
import { PathSelector } from "../editor/logic";
import {
  FulfillmentType,
  Image as ImageType,
  Maybe,
  Product,
  ProductLabels,
  ProductMobile,
  ProductPresentation,
  ProductStep,
  TaxRate,
} from "../lib/schema.generated";
import { useVapianoConfigContext } from "./vapianoConfigContext";
import { TranslatedString } from "../lib/scalars";
import { GetKeys } from "../lib/types";
import { expandTranslatedString, setProperty } from "../lib/utils";
import {
  useEditorContext,
  useClientContext,
  useUserContext,
} from "../editor/context";
import { findHiddenLanguage, isInputHidden } from "../editor/utils";
import LoadingPlaceholder from "../components/LoadingIcon";

/*interface RouteParams {
  slug: string;
}

interface MyComponent extends RouteComponentProps<RouteParams> {}*/

export const ProductDetails: React.FC = () => {
  //console.log(props. .match.params);
  const ctx = useVapianoConfigContext();
  const editorCtx = useEditorContext();
  const clientCtx = useClientContext();
  const userCtx = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { queryParams: string | undefined } | null;
  const queryParams =
    state !== null &&
    state.queryParams !== undefined &&
    state.queryParams !== null
      ? state.queryParams
      : undefined;

  const params = useParams<"productId" | "menuId">();
  const [currentProduct, setCurrentProduct] = React.useState<Product | null>(
    null
  );

  // find product
  if (params.productId === undefined) throw new Error("productId undefined");
  if (params.menuId === undefined) throw new Error("menuId undefined");

  const productId = Number.parseInt(params.productId);

  if (Number.isNaN(productId))
    throw new Error(`productId '${params.productId}' is NaN`);

  const product = ctx.document.menu.products.find((p) => p.id === productId);

  const productIndex = ctx.document.menu.products.findIndex(
    (p) => p.id === productId
  );

  // other logic

  if (product === undefined)
    throw new Error(`product with id ${productId} not found`);

  const productById =
    (id: number): PathSelector =>
    (product: unknown) =>
      (product as Product).id === id;

  useEffect(() => {
    setCurrentProduct({
      ...product,
      name: expandTranslatedString(product.name),
      description: expandTranslatedString(product.description),
    });
  }, [product]);

  const stepsForStepId = ctx.document.menu.steps.map((step) => {
    return {
      label: `(${step.id}) ${step.editorName}`,
      value: step.id,
    };
  });

  const onProductStepValueChange = useCallback(
    (key: string, index: number) =>
      (value: string | number | null | boolean | (string | number)[]) => {
        if (currentProduct !== null) {
          const copyOfProductSteps = [...currentProduct.productSteps];
          copyOfProductSteps[index] = {
            ...copyOfProductSteps[index],
            [key]: value,
          };
          const newProductSteps = setProperty(
            currentProduct,
            "productSteps",
            copyOfProductSteps
          );
          setCurrentProduct(newProductSteps);
        }
      },
    [currentProduct, setCurrentProduct]
  );

  /*const taxRates = useCallback(
    () => () => {
      return Object.entries(ctx.taxRates).map(([key, value]) => {
        var val: TaxRate;
        switch (key) {
          case "A":
            val = TaxRate.A;
            break;
          case "B":
            val = TaxRate.B;
            break;
          case "C":
            val = TaxRate.C;
            break;
          case "D":
            val = TaxRate.D;
            break;
          case "E":
            val = TaxRate.E;
            break;
          default:
            val = TaxRate.A;
            break;
        }
        return {
          label: key + " - " + value + "" + "%",
          value: val,
        };
      });
    },
    [ctx.taxRates]
  );*/
  /*
  [
              { label: "A", value: TaxRate.A },
              { label: "B", value: TaxRate.B },
              { label: "C", value: TaxRate.C },
              { label: "D", value: TaxRate.D },
              { label: "E", value: TaxRate.E },
            ]
  */

  const onStepDeleteButtonClick = useCallback(
    (index: number) => () => {
      if (currentProduct !== null) {
        if (window.confirm("You sure about that?")) {
          const copyOfProductSteps = [...currentProduct.productSteps];
          copyOfProductSteps.splice(index, 1);
          const newProduct = setProperty(
            currentProduct,
            "productSteps",
            copyOfProductSteps
          );
          setCurrentProduct(newProduct);
        }
      }
    },
    [currentProduct, setCurrentProduct]
  );

  const newProductStep: ProductStep = {
    availableForFulfillment: [FulfillmentType.DineIn, FulfillmentType.TakeAway],
    position: 0,
    stepId: 0,
    visible: true,
    isExtra: false,
  };

  const onAddProductStepButtonClick = useCallback(() => {
    if (currentProduct !== null) {
      const copyOfProductSteps = [...currentProduct.productSteps];
      copyOfProductSteps.push(newProductStep);
      const newProduct = setProperty(
        currentProduct,
        "productSteps",
        copyOfProductSteps
      );
      setCurrentProduct(newProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct, setCurrentProduct]);

  const prefix = ["menu", "products", productById(product.id)];

  const productStepColumns: ColumnsType<ProductStep> = [
    {
      title: "Step",
      dataIndex: "stepId",
      key: "stepId",
      render: (v: unknown, productStep: ProductStep, index: number) => (
        <Select
          label=""
          placeholder="select an option"
          value={productStep.stepId}
          options={stepsForStepId}
          onChange={onProductStepValueChange("stepId", index)}
        />
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (v: unknown, productStep: ProductStep, index: number) => (
        <InputNumber
          label=""
          onChange={onProductStepValueChange("position", index)}
          value={productStep.position}
          nullable
          integer
        />
      ),
    },
    {
      title: "Available for",
      dataIndex: "availableForFulfillment",
      key: "availableForFulfillment",
      render: (v: unknown, productStep: ProductStep, index: number) => (
        <Select
          label={undefined}
          placeholder="fulfillment type"
          value={
            productStep.availableForFulfillment !== null
              ? productStep.availableForFulfillment
              : FulfillmentType.DineIn
          }
          multiple={true}
          options={[
            { label: "DineIn", value: FulfillmentType.DineIn },
            { label: "TakeAway", value: FulfillmentType.TakeAway },
          ]}
          onChange={onProductStepValueChange("availableForFulfillment", index)}
        />
      ),
    },
    {
      title: "Visible",
      dataIndex: "visible",
      key: "visible",
      render: (v: unknown, productStep: ProductStep, index: number) => (
        <Checkbox
          value={productStep.visible}
          onChange={onProductStepValueChange("visible", index)}
        />
      ),
    },
    {
      title: "Is Extra",
      dataIndex: "isExtra",
      key: "isExtra",
      render: (v: unknown, productStep: ProductStep, index: number) => (
        <Checkbox
          value={productStep.isExtra}
          onChange={onProductStepValueChange("isExtra", index)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (v: unknown, productStep: ProductStep, index: number) => (
        <Button type="link" onClick={onStepDeleteButtonClick(index)}>
          Delete
        </Button>
      ),
      width: 100,
    },
  ];

  const productStepTableRowKey = useCallback(
    (ps: ProductStep, index: number | undefined): string => {
      return index?.toString() ?? ps.stepId.toString();
    },
    []
  );

  const onProductValueChange = useCallback(
    (key: GetKeys<Product>) =>
      (
        value:
          | string
          | number
          | null
          | undefined
          | Maybe<string[]>
          | Maybe<ProductMobile>
          | (string | number)[]
          | TranslatedString
          | (ImageType | null)
          | boolean
      ) => {
        if (currentProduct !== null) {
          const newProduct = setProperty(currentProduct, key, value);
          setCurrentProduct(newProduct);
        }
      },
    [setCurrentProduct, currentProduct]
  );

  const onSaveClick = useCallback(() => {
    const productIndex = ctx.document.menu.products.findIndex(
      (p) => p.id === product.id
    );
    editorCtx.dispatch({
      type: "replaceItem",
      path: ["menu", "products"],
      item: currentProduct,
      index: productIndex,
    });
    if (params.menuId !== undefined)
      navigate({
        pathname: `/dashboard/menu/${params.menuId}/products`,
        search: queryParams,
      });
  }, [
    ctx.document.menu.products,
    editorCtx,
    currentProduct,
    navigate,
    params.menuId,
    product.id,
    queryParams,
  ]);

  if (currentProduct === null) return <LoadingPlaceholder />;

  return (
    <div className="form">
      <Row>
        <PageHeader
          title="Product details"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
          extra={
            <Space wrap>
              <ActionButton
                label="Remove"
                action="remove"
                type="primary"
                dataPath={["menu", "products"]}
                value={ctx.document.menu.products}
                defaultValue={productIndex}
                nav={
                  params.menuId !== undefined
                    ? {
                        pathname: `/dashboard/menu/${params.menuId}/products`,
                        search: queryParams,
                      }
                    : undefined
                }
                danger
              />
              <Button type="primary" onClick={onSaveClick}>
                Save
              </Button>
            </Space>
          }
        />
      </Row>
      <Row gutter={8}>
        <Col
          span={4}
          hidden={isInputHidden(
            "id",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="ID"
            value={currentProduct.id}
            onChange={onProductValueChange("id")}
            disabled
            hidden={isInputHidden(
              "id",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "externalId",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputString
            label="External ID"
            tooltipText="A saját éttermi rendszerben"
            onChange={onProductValueChange("vendel.externalId")}
            value={currentProduct.vendel.externalId}
            hidden={isInputHidden(
              "externalId",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "availableForFulfillment",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Available for"
            tooltipText="Kiválasztandó, ha az étel csak elvitelre vagy csak helyben fogyasztásra kérhető"
            placeholder="fulfillment type"
            value={currentProduct.availableForFulfillment as string}
            nullable
            options={[
              { label: "DineIn", value: FulfillmentType.DineIn },
              { label: "TakeAway", value: FulfillmentType.TakeAway },
            ]}
            onChange={onProductValueChange("availableForFulfillment")}
            hidden={isInputHidden(
              "availableForFulfillment",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "presentation",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Presentation"
            placeholder="presentation type"
            value={currentProduct.presentation}
            options={[
              {
                label: "Configurable",
                value: ProductPresentation.Configurable,
              },
              { label: "Simple", value: ProductPresentation.Simple },
            ]}
            onChange={onProductValueChange("presentation")}
            hidden={isInputHidden(
              "presentation",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "pager",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Checkbox
            label="Pager"
            value={currentProduct.pager}
            onChange={onProductValueChange("pager")}
            hidden={isInputHidden(
              "pager",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>

      <Row gutter={8}>
        <Col
          span={12}
          hidden={isInputHidden(
            "availableDates",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Tag
            label="Available Dates"
            tooltipText="A termék csak ezeken a napokon elérhető. Szükséges formátum pl: 2012-01-01"
            dataPath={[...prefix, "availableDates"]}
            value={currentProduct.availableDates}
            nullable
            onChange={onProductValueChange("availableDates")}
            hidden={isInputHidden(
              "availableDates",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col
          span={4}
          hidden={isInputHidden(
            "price",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="Price"
            value={currentProduct.price}
            nullable
            onChange={onProductValueChange("price")}
            hidden={isInputHidden(
              "price",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "statPrice",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="StatPrice"
            value={currentProduct.statPrice}
            nullable
            onChange={onProductValueChange("statPrice")}
            hidden={isInputHidden(
              "statPrice",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "containerPrice",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="ContainerPrice"
            value={currentProduct.containerPrice}
            nullable
            onChange={onProductValueChange("containerPrice")}
            hidden={isInputHidden(
              "containerPrice",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>

        <Col
          span={4}
          hidden={isInputHidden(
            "position",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="Position"
            value={currentProduct.position}
            onChange={onProductValueChange("position")}
            integer
            nullable
            hidden={isInputHidden(
              "position",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "dineIn",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Dine In Tax Rate"
            tooltipText="Helyben fogyasztás ÁFA kulcs"
            value={currentProduct.taxRates.dineIn}
            options={Object.entries(ctx.taxRates).map(([key, value]) => {
              let val: TaxRate;
              switch (key) {
                case "A":
                  val = TaxRate.A;
                  break;
                case "B":
                  val = TaxRate.B;
                  break;
                case "C":
                  val = TaxRate.C;
                  break;
                case "D":
                  val = TaxRate.D;
                  break;
                case "E":
                  val = TaxRate.E;
                  break;
                default:
                  val = TaxRate.A;
                  break;
              }
              return {
                label: key + " - " + value.toString() + "" + "%",
                value: val,
              };
            })}
            onChange={onProductValueChange("taxRates.dineIn")}
            hidden={isInputHidden(
              "dineIn",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "takeAway",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Take Away Tax Rate"
            tooltipText="Elviteles ÁFA kulcs"
            value={currentProduct.taxRates.takeAway}
            options={Object.entries(ctx.taxRates).map(([key, value]) => {
              let val: TaxRate;
              switch (key) {
                case "A":
                  val = TaxRate.A;
                  break;
                case "B":
                  val = TaxRate.B;
                  break;
                case "C":
                  val = TaxRate.C;
                  break;
                case "D":
                  val = TaxRate.D;
                  break;
                case "E":
                  val = TaxRate.E;
                  break;
                default:
                  val = TaxRate.A;
                  break;
              }
              return {
                label: key + " - " + value.toString() + "" + "%",
                value: val,
              };
            })}
            onChange={onProductValueChange("taxRates.takeAway")}
            hidden={isInputHidden(
              "takeAway",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>

      <Row gutter={8}>
        <Col
          span={6}
          hidden={isInputHidden(
            "editorName",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputString
            label="Editor name"
            tooltipText="Munkanév, csak az editorban jelenik meg"
            value={currentProduct.editorName}
            onChange={onProductValueChange("editorName")}
            hidden={isInputHidden(
              "editorName",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "receiptMark",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Checkbox
            label="Receipt mark"
            value={currentProduct.receiptMark}
            onChange={onProductValueChange("receiptMark")}
            hidden={isInputHidden(
              "receiptMark",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>

      <InputTranslated
        label="Name"
        tooltipText="A termék neve, ami megjelenik a kioskon"
        value={currentProduct.name}
        onChange={onProductValueChange("name")}
        hidden={isInputHidden(
          "name",
          "products",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(
          clientCtx.selectedClient,
          "products"
        )}
      />

      <InputTranslated
        label="Description"
        tooltipText="A termék leírása"
        value={currentProduct.description}
        nullable
        multiLine={true}
        onChange={onProductValueChange("description")}
        hidden={isInputHidden(
          "description",
          "products",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(
          clientCtx.selectedClient,
          "products"
        )}
      />

      <Row gutter={8}>
        <Col
          span={4}
          hidden={isInputHidden(
            "labels",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Labels"
            multiple={true}
            value={currentProduct.labels}
            options={[
              { label: "CustomConfig", value: ProductLabels.CustomConfig },
            ]}
            onChange={onProductValueChange("labels")}
            hidden={isInputHidden(
              "labels",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>

      <Row gutter={8}>
        <Col
          span={24}
          hidden={isInputHidden(
            "mobile",
            "products",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <CheckboxNull
            value={currentProduct.mobile}
            defaultValue={{ menuItemImage: null, productCoverImage: null }}
            onChange={onProductValueChange("mobile")}
            hidden={isInputHidden(
              "mobile",
              "products",
              clientCtx.selectedClient,
              userCtx.user
            )}
          >
            <h3>Enable picture</h3>
          </CheckboxNull>
          {currentProduct.mobile !== null && (
            <Row gutter={8}>
              <Col span={8}>
                <Image
                  label="Menu Item Image"
                  nullable
                  value={currentProduct.mobile.menuItemImage}
                  mediaFiles={ctx.mediaFiles}
                  onChange={onProductValueChange("mobile.menuItemImage")}
                />
              </Col>
              <Col span={8}>
                <Image
                  label="Product Cover Image"
                  nullable
                  value={currentProduct.mobile.productCoverImage}
                  mediaFiles={ctx.mediaFiles}
                  onChange={onProductValueChange("mobile.productCoverImage")}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {currentProduct.presentation === ProductPresentation.Configurable && (
        <Row>
          <Col span={24}>
            <PageHeader
              title="Product Steps"
              extra={[
                <Button
                  key="AddStepButton"
                  onClick={onAddProductStepButtonClick}
                  type="primary"
                >
                  Add Step
                </Button>,
              ]}
            />

            <Table
              rowKey={productStepTableRowKey}
              columns={productStepColumns}
              tableLayout="fixed"
              sticky={true}
              pagination={false}
              dataSource={currentProduct.productSteps}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
